import React from "react"
import { Link, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/header/hero'
import Newsletter from '../components/footer/newsletter'
import Container from '../components/container'

export default ({ data }) => {
  return (
    <Layout>
      <Hero mode="interactive2024" title="Title" content="Lorem ipsum dolor sit amet"  button={{title:'',url:'',target:''}}/>
      <SEO title={data.wordpressPage.yoast_title} meta={data.wordpressPage.yoast_meta}/>
        <div className="container__about">
          <div className="about">
            <h1>{data.wordpressPage.acf.intro_text}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.intro}}/>
          </div>
          <Container>
            <div className="container__boxes" id="giveGetBoxes">
              <Link
                className="giveBox"
                to={data.wordpressPage.acf.homepage_boxes.box_top_url.url}
              >
                <div className="center">{data.wordpressPage.acf.homepage_boxes.box_top_text}</div>
              </Link>
              <Link className="imageBlend" to={data.wordpressPage.acf.homepage_boxes.box_1_url}>
                <BackgroundImage
                  Tag="div"
                  className="box"
                  fluid={data.wordpressPage.acf.homepage_boxes.box_1_image.localFile.childImageSharp.fluid}
                >
                  <div className="center" dangerouslySetInnerHTML={{__html: data.wordpressPage.acf.homepage_boxes.box_1_text}} />
                </BackgroundImage>
              </Link>
              <Link className="imageBlend" to={data.wordpressPage.acf.homepage_boxes.box_2_url}>
                <BackgroundImage
                  Tag="div"
                  className="box"
                  fluid={data.wordpressPage.acf.homepage_boxes.box_2_image.localFile.childImageSharp.fluid}
                >
                  <div className="center" dangerouslySetInnerHTML={{__html: data.wordpressPage.acf.homepage_boxes.box_2_text}} />
                </BackgroundImage>
              </Link>
              <Link target="_blank" className="imageBlend" to={data.wordpressPage.acf.homepage_boxes.box_3_url}>
                <BackgroundImage
                  Tag="div"
                  className="box"
                  fluid={data.wordpressPage.acf.homepage_boxes.box_3_image.localFile.childImageSharp.fluid}
                >
                  <div className="center" dangerouslySetInnerHTML={{__html: data.wordpressPage.acf.homepage_boxes.box_3_text}} />
                </BackgroundImage>
              </Link>
            </div>
          </Container>
        </div>
        <Newsletter />
    </Layout>
  )
}
export const pageQuery = graphql`
query Home2024 {
  wordpressPage(wordpress_id: {eq: 10}) {
    id
    title
    acf {
      intro_text
      intro
      homepage_boxes {
        box_1_image {
          source_url
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 732) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        box_2_image {
          source_url
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 732) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        box_3_image {
          source_url
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 732) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        box_top_url {
          url
          target
          title
        }
        box_top_text
        box_1_url
        box_2_url
        box_3_url
        box_1_text
        box_2_text
        box_3_text
      }
    }
    wordpress_id
    guid
    slug
    content
    yoast_meta {
      name
      property
    }
    yoast_title
  }
}
`